#item {
    margin: 2rem;
    width: 25rem;
    height: 30rem;
    background-color: var(--primary);
    border-radius: .5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.thumb-container {
    width: 100%;
    height: 45%;
    overflow:hidden;
    background-color: #c1c1c1;

}
.thumb-container img {
    width: 100%;
    height: 100%;
    object-fit:cover;
}


.item-preview-content {
    padding: .5rem;
    display: flex;
    flex-direction: column;

}

.item-preview-title {
    margin: 0;
    margin: 1rem 0;
    color: white;
    font-weight: 600;
    font-size: 1.8rem;
}

.item-preview-location {
    margin: 0;
    color: var(--secondary);
    font-weight: 200;
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.item-preview-date {
    margin: 0;
    
    font-weight: 600;
    font-size: 1.5rem;
}