nav {
  display: inline-flex;
  float: right;
}

p {
  color: white;
  display: inline;
}

.header-container {
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 5;
}

.header-container__shell {
  margin: 0 auto;
  padding-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.header-conatiner__top,
.header-conatiner__nav_top {
  margin: 0;
  padding: 5px;
  width: 100%;
  display: flex;
 
}

.header-conatiner__top{
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
}

.wrapper {
  display: flex;
  align-items: center;
}

.header-conatiner__top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
 
  background-color: var(--primary);
  z-index: 1000;
}

.header-conatiner__nav_top {
  height: 30px;
  
  z-index: 999;
}

.header-container__info {
  display: flex;
  justify-content: center;
  padding: 3px 15px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
}

.branding-header-container {

  width: 200px;
  
 
}


.header-brand-text {
  display: none;
}

.header-container__title {
  color: white;
}

.header-container__nav {
  list-style: none;
}

.header-container__link {
  display: inline;
}

.main-navigation__menu-btn {
  margin: 8px 3px;
  width: 2.5rem;
  height: 2rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 2rem;
  height: 2px;
  background: white;
}

.main-navigation__header-nav {
  display: flex;
}

.header-container__brand {
  width: 50%;
  display: grid;
  font-family: 'Roboto', sans-serif;
}



@media only screen and (max-width: 450px) {
  .header-container__info {
    width: 100%;
    font-size: 1.5rem;
  }
  .header-email {
    width: 100%;
    text-align: center;
  }
}
