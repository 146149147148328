#feature {
    min-height: 43rem;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5rem 2rem;
    background-color: #eee;
    border-bottom: 1px solid #ccc;
}

#video {
    width: 50%;
    max-width: 94rem;
}

.video-container {
    width: 100%;
}

#info {
    width: 40%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
}



.info-body {
 flex-grow: 2;
 padding: 4rem 0;
 border-bottom: 1px solid darkgray;
}

.info-body p {
    color: grey;
    font-size: 1.4rem;
    font-weight: 400;
white-space:pre-wrap;
}

.info-footer {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.featured-not-found {
    color: var(--primary);
    text-align: center;
    font-size: 2rem;
}



@media (max-width:1200px) {
    #feature {
       flex-direction: column;
    }
    #video, #info {
        
        width:95%;
    }
}

@media (max-width:768px) {
    #feature {
        width: 100%;
        padding: 0;
    }
   
    #video {
        width:100%;
    }
    #info {
        width: 100%;
        padding: 0;
    }
    .feature-title {
        font-size: 6vw;
    }
    .feature-sub-title {
        font-size: 3.5vw;
    }
}

@media (max-width:480px) {

   
    #video {
        width:100%;
    }
    #info {
        width: 100%;
        padding: 0;
    }
}