.top-links {
   
    margin:0;
    padding: 0;
   position: absolute;
   width: 5rem;
   height: 5rem;
   top: .5rem;
   right: .5rem;
    display: inline-flex;
    justify-items: center;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 300;
    color: white;
  }

 

  .top-links a, .top-links__logout {
    color: var(--primary);
    text-decoration: none;
    transition: .3s;
  }

  .top-links a {
   
    padding: 0.5rem;
  }


.top-links a:hover,
.top-links a.active, 
.top-links__logout:hover
 {
  
  color: var(--secondary);
  
}

.top-links__logout:active{
  color: #818181;
  color: var(--secondary);
}

.top-links__logout {
    cursor: pointer;
    background: transparent;
    font: inherit;
    border: none;
    color: white;
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  
  .top-links__logout:focus {
    outline: none;
    color: var(--secondary);
  }
  
  .top-links__logout:hover,
  .top-links__logout:active {
    color: var(--secondary);
  }
  

  