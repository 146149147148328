.ev-branding {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  float: left;
  width: 100%;
}

.ev-branding img {
  display: block;
  padding: 2px;
  width: 20rem;
  height: 11rem;
  object-fit: contain;
}

.ev-branding h1 {
  
  margin-left: 2rem;
  font-size: 3rem;
  color: var(--secondary) !important;
}

@media only screen and (max-width: 768px) {
  .ev-branding {
    flex-wrap: nowrap;
    padding-bottom: 2rem;
  }

  .ev-branding h1 {
    text-align: left;
    margin-left: 1rem;
    font-size: 4vw;
  
  }

  .ev-branding img {
    width: 15rem;
    height: 8rem;
  }
}


@media only screen and (max-width: 458px) {
  .ev-branding img {
    width: 7rem;
    height: 4rem;
  }

}
