#item-list {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    border-top:.1rem solid #ddd; 

}

.item-list-h2 {
   margin: 0 2rem;
    font-size: 1.6rem;
    color: var(--primary);
    border-bottom: 1px solid var(--secondary);
}

.slider_containter {
    width:100%;
    height: 30rem;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
}

@media only screen and (min-width: 2300px) {
    .slider_containter {
        justify-content: space-around;
    }
}

@media only screen and (max-width: 768px) {
    .slider_containter {
        width:100%;
        height: 40rem;
        overflow-x: scroll;
        overflow-y: hidden;
        display: flex;
        padding-bottom: 1rem;
    }
}