


.auth-wrapper {
    width: 90%;
    max-width: 800px;
    padding-top: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-card,
.forgot-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 2rem;
    font-size: 1.6rem;
    max-width: 50rem;
}

.login-card h1 {
    padding-top: 3rem;
}

.login-form {
    display: flex;
    flex-wrap: wrap;

}
.forgot-form__wrapper,
.auth-form__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
   
}

.auth-form__wrapper {
    border-bottom: 1px solid #ccc;
}
.request-message{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
}

.request-message-text p {
    
    font-size: 1.6rem;
    color: #333;
}
.ticket-label-title {
    text-align: left;
    color: var(--secondary);
    width: 100%;
    padding: 0 10%;
}

.auth-ticket-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--input-border);
    border-radius: .5rem;
    margin: 0 10%
}

.input_wrapper {
    width: 100%;;
    display: flex;
    flex-direction: column;

}


.ticket-input {
    margin-bottom: 0;
    border: 0px;
    text-align: center;
    max-width: 15rem;
}

.ticket-input-grow {
    flex-grow: 2;
    min-width: 10.6rem;
}

.ticket-input-container {
    margin-bottom: 0;

}

.ticket-marker{
    font-weight: 200;
    font-size: 2rem;
    padding-bottom: 1rem;
}

.login-btn {
    font-size: 2rem;
    font-weight: 400;
    width: 50%;
    
}

.action_wrapper {
    display: flex;
    justify-content: center;
}

.enrol-btn {
    margin: 0;
    font-size: 2rem;
    font-weight: 400;
    width: 30%;
}

.enrol-cta-container {
    margin: 7px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.enrol-cta-container p {
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    font-weight: 500;
}

.request-link  {
    font-size: 1.8rem;
   
}

.auth-register-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth-register {
    font-size: 1.8rem;
}

.auth-register-text {
    font-size: 1.8rem;
    color: var(--primary);
}




.reset-sent-notice {
    color: var(--secondary);
    margin: 0;
    padding: 10px 20px;
}

.auth_brand {
    width: 100%;
    max-width: 350px;
}

.ticket-request-title {
    color: var(--primary);
    margin: 0;
}


.setTicketNo {
    border: 1px solid grey;
    padding: 2rem 5rem;
    box-shadow: 1px 1px 2px 2px #888888;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    color: #333;
    font-weight: 600;
}

.password-set-errors {
    color: red;
    font-weight: 600;
    font-size: 1.4rem;
}
@media only screen and (max-width:450px) {
    .forgot-card,
    .enrol-card,
    .login-card {
        margin: 0;
        border-radius: 0;
        padding: 0px;
        
    }
    .auth-wrapper {
        overflow-x: hidden;
        padding: 0;
        padding-top: 25%;
        
    }
    .form-padding {
       margin:0 10px;
       
    }
    .login-btn {
        width: 91%;
    }

    .enrol-cta-container {
        flex-wrap: wrap;
    }

    .enrol-cta-container p {
        width: 100%;
        padding: 10px 0;
        text-align: center;
    }

    .enrol-btn {
        width: 100% !important;
    }

    .auth_brand{
        margin-top: 10%;
        width: 80%;
    }
    .auth-ticket-wrapper{
        margin: 0 1rem;
    }
    .ticket-label-title {
        padding: 0 1rem;
    }
}

.ticket-errors {
    color: darkred;
}

.ticket-notice {
    color: var(--primary);
}