.more-info-container {
display: flex;
flex-direction: column;

}

.more-info-text {
    color: var(--primary);
    margin: 0;
}

.more-info-link {
    color: var(--secondary);
    transition: .3s;
    margin-bottom: 1rem;

}

.more-info-link:hover {
    color: var(--primary);
}