footer {
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 0;
}

.main-footer__shell {
    margin: 0 auto;
    padding: 0 3rem;
    padding-bottom: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
   
   
}

.main-footer__brand {
    padding: 2%;

}

.main-footer__links {
    padding: 1.5rem;
    float: left;
}

@media (max-width: 391px) {
    .main-footer__links {
        margin:0;
        width: 100%;
    }
  }

.main-footer__contact {
    
    margin-right: 1rem;
    padding: 1.5rem;
    float: right;
}

.main-footer__bottom {
    margin: 0;
    padding: .5rem;
    padding-top: 1.5rem;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    text-align: right;
    font-size: 1.4rem;

}
.main-footer__bottom p {
  color: grey;
}