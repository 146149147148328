.main-nav__wrapper {
  color: #ffffff;
  padding-right: 10px;
  font-size: 1.3rem;
  font-weight: 400;
  display: none;
}

.nav-draw__wrapper {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  min-height: 100vh;
  width: 250px;
  padding-top: 90px;
  background: rgb(221, 221, 221);
  background: -moz-linear-gradient(
    90deg,
    rgba(221, 221, 221, 1) 31%,
    rgba(201, 201, 201, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(221, 221, 221, 1) 31%,
    rgba(201, 201, 201, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(221, 221, 221, 1) 31%,
    rgba(201, 201, 201, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dddddd",endColorstr="#c9c9c9",GradientType=1);
  z-index: 999;
  transition: all 0.3s ease;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: scroll;
  box-shadow: -3px 0px 3px rgba(0, 0, 0, 0.2);
}

.mobile-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-top: 2%;
  padding: 0;
  width: 100%;
}
.mobile-nav li {
  margin: 5px 0;
  text-align: left;
  font-size: 2rem;
  border-bottom: 1px dashed #aaa;
}

.mobile-nav-link-button {
  outline: none;
  border: 0;
  background: none;
}

.mobile-nav-link {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-weight: 400;
  color: #666;
  text-decoration: none;
  font-size: 2rem;
}
.mobile-nav-link span {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--primary);
}
.mobile-nav-link:hover {
  background-color: var(--secondary);
}

.bar-container {
  display: inline-block;
  cursor: pointer;
  position: fixed;
  top: 35px;
  right: 20px;
  color: white;

  padding: 10px;
  border-radius: 10px;
  z-index: 1000;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 2px;
  background-color: #818181;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  width: 30px;
  -webkit-transform: rotate(-45deg) translate(-4px, 3px);
  transform: rotate(-45deg) translate(-4px, 3px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  width: 30px;
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.close {
  width: 0;
}


