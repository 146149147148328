.time-container {
    display: flex;
    background-color: var(--primary);
    color: white;
    margin: .5rem;
    margin-left: 0;
    border-radius: 1rem;
    padding: 0 2rem;
    width: fit-content;
}

.time-container p {
    font-size: 1.1rem;
    font-weight: 600;
}
