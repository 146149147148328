.feature-header {
    display: flex;
    flex-direction: column;
}

.feature-title {
    margin: 1rem 0 .3rem 0;
    color: var(--primary);
    font-size: 4rem;
    font-weight: 300;
}

.feature-sub-title {
    margin: .3rem 0 1rem 0;
    color: var(--secondary);
    font-size: 2rem;
    font-weight: 600;
}

.feature-location {
    margin: 0;
    color: var(--primary);
    font-size: 1.8rem;
    font-weight: 900;
}

@media only screen and (max-width: 768px) {
    .feature-header {
       padding: 0 1rem;
    }

    .feature-title {
        font-size: 6vw;
        font-weight: 500;
    }
    
    .feature-sub-title {
        font-size: 4vw;
    }


}

@media only screen and (max-width: 458px) {
    .feature-header {
       padding: 0 1rem;
    }

    .feature-title {
        font-size: 6vw;
        font-weight: 500;
    }
    
    .feature-sub-title {
        font-weight: 900;
    }


}