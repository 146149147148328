@import url(https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap);

html {
  margin: 0;
  position: relative;
  min-height: 100vh;
  font-size: 62.5%;
  
}

body {
  margin: 0; 
  min-height: 650px;
  font-family: 'Red Hat Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  color: #c3c3c3;
}

:root {
  --primary-100: #6650AA;
  --primary: #4D397D;
  --primary-500: #32245E;

  --secondary-100: #EEEEEE;
  --secondary: #EA8900;
  --secondary-500: #bf7002;

  --input-color: #b0b0b0;
  --input-border: #d2d2d2;
  --input-background: #fff;
  --input-placeholder: #CBD1DC;

  --input-border-focus: #6650AA;

  --group-color: var(--input-color);
  --group-border: var(--input-border);
  --group-background: #ffeeee;

  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: var(--primary);

}

a {
  color: #4D397D;
  color: var(--primary);
}

a:hover {
  color: #32245E;
  color: var(--primary-500);
}

.helogale {
  color: white;
  text-decoration: none;
  opacity: .6;
}

.center {
  margin: 0 auto;
  text-align:  center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.right {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  text-align: right;
}

.left {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  text-align: left;
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  
}

.page-wrapper {
  margin: 0 auto;
  padding-top: 8rem;
  margin-bottom: 5.6rem;
  width: 100%;
  min-height: 500px;
}


.header-container {
  position: fixed;
  z-index: 500 !important;
}
.input-wrapper {
    margin-bottom: 1rem;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.form-field {
    display: block;
    width: 100%;
    padding: .8rem 1.6rem;
    line-height: 2.5rem;
    font-size: 1.6rem;
    font-weight: 500;
    font-family: inherit;
    border-radius: 6px;
    -webkit-appearance: none;
    color: var(--input-color);
    border: 1px solid var(--input-border);
    background: var(--input-background);
    transition: border .3s ease; 
}

.no-group {
    border-radius: 6px!important;
}

.form-field::-webkit-input-placeholder {
    color: var(--input-placeholder);
}

.form-field:-ms-input-placeholder {
    color: var(--input-placeholder);
}

.form-field::placeholder {
    color: var(--input-placeholder);
}

.form-field:focus {
    outline: none;
    border-color: var(--input-border-focus);
}

.form-group {
    margin: 0;
    position: relative;
    display: -webkit-flex;
    display: flex;
    width: 100%;
}

.form-padding {
    margin: 0 10%;
}

.form-group span, .form-group .form-field {
    white-space: nowrap;
    display: block;
    border-radius: 6px;
}

.form-group .form-field:not(:first-child):not(:last-child),
.form-group span:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.form-group .form-field:first-child,
.form-group span:first-child {
    border-radius: 6px 0 0 6px;
}

.form-group .form-field:last-child,
.form-group span:last-child{
    border-radius: 0 6px 6px 0;
}

.form-group .form-field:not(:first-child),
.form-group .span:not(:first-child) {
    margin-left: -.1rem;
}


.form-group .form-field {
    position: relative;
    z-index: 1;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 1%;
    margin-top: 0;
    margin-bottom: 0;
}

.form-group span {
    text-align: center;
    padding: .8rem 1.2rem;
    font-size: 1.4rem;
    line-height: 2.5rem;
    color: var(--group-color);
    background: var(--group-background);
    border: 1px solid var(--group-border);
    transition: background .3s ease, border .3s ease, color .3s ease;
}

.form-group:focus-within span {
        color: var(--group-color-focus);
        background: var(--group-background-focus);
        border-color: var(--group-border-focus);
    }

   

   .inputError {
       
        color: darkred;
        font-weight: 600;
    
}
.button {
    margin: 1.5rem;
    padding: 1rem 2rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 5px;
    border-width: 1px;
    background-color: var(--primary);
    border: 1px solid var(--primary-500);
    color: white;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    transition: all .35s;
}

.button span{
    position: relative;
    z-index: 2;
  }

.button:after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: var(--primary-500);
    transition: all .35s;
    border-radius: 5px;
  }

.button:hover:after {
    width: 100%;
  }

.button:focus, .button:active {
    border: 1px solid white;
    outline: none;
}

.button-40 {
    width: 40%;
}

.button-50 {
    width: 50%;
}

.button-100 {
    width: 100%;
}

.button-primary {
    background-color: var(--primary);
    border: 1px solid var(--primary-500);
    color: white;
}
.button-primary:after{
    background-color: var(--primary-500);
}


.button-secondary {
    background-color: var(--secondary);
    border: 1px solid var(--secondary-500);
    color: white;
}
.button-secondary:after{
    background-color: var(--secondary-500);
}

.button-danger {
    background-color: #B70F0F;
    border: 1px solid #D53D3D;
    color: white;
}
.button-danger:after{
    background-color: #770808;
}

.button-green {
    background-color: #168612;
    border: 1px solid #10640d;
    color: white;
}
.button-green:after{
    background-color: #10640d;
}

.button-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #7B6715;
}
.button-warning:after{
    background-color: #e0a800;
}

.button-outline-primary{
    background-color: transparent;
    border: 1px solid var(--primary);
    color: var(--primary);
}
.button-outline-primary:after{
    background-color: white;
}

.button-outline-white{
    background-color: transparent;
    border: 1px solid white;
    color: white;
}
.button-outline-white:after{
    background-color: white;
}
.button-outline-white:hover{
    color: black;
}

.button-white{
    color: black;
    background-color: white;
    border: 1px solid white;
}
.button-white:hover {
    color: white;
}
.button-white:after{
    background-color: black;   
}

.button-disabled {
    background-color: gray;
    color: darkgray;
    border: 1px solid darkgray;
}

.button-disabled:after {
    background-color: gray;
}

.button a {
    color: white;
    position: relative;
    z-index: 2;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    font-size: 2rem;
    width: 100%;
}




.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 3px solid var(--primary);
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--primary) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}

@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}



.auth-wrapper {
    width: 90%;
    max-width: 800px;
    padding-top: 10rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.login-card,
.forgot-card {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    padding-bottom: 2rem;
    font-size: 1.6rem;
    max-width: 50rem;
}

.login-card h1 {
    padding-top: 3rem;
}

.login-form {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;

}
.forgot-form__wrapper,
.auth-form__wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 1.6rem;
   
}

.auth-form__wrapper {
    border-bottom: 1px solid #ccc;
}
.request-message{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 1rem 2rem;
}

.request-message-text p {
    
    font-size: 1.6rem;
    color: #333;
}
.ticket-label-title {
    text-align: left;
    color: var(--secondary);
    width: 100%;
    padding: 0 10%;
}

.auth-ticket-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border: 1px solid var(--input-border);
    border-radius: .5rem;
    margin: 0 10%
}

.input_wrapper {
    width: 100%;;
    display: -webkit-flex;;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;

}


.ticket-input {
    margin-bottom: 0;
    border: 0px;
    text-align: center;
    max-width: 15rem;
}

.ticket-input-grow {
    -webkit-flex-grow: 2;
            flex-grow: 2;
    min-width: 10.6rem;
}

.ticket-input-container {
    margin-bottom: 0;

}

.ticket-marker{
    font-weight: 200;
    font-size: 2rem;
    padding-bottom: 1rem;
}

.login-btn {
    font-size: 2rem;
    font-weight: 400;
    width: 50%;
    
}

.action_wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.enrol-btn {
    margin: 0;
    font-size: 2rem;
    font-weight: 400;
    width: 30%;
}

.enrol-cta-container {
    margin: 7px;
    padding: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.enrol-cta-container p {
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    font-weight: 500;
}

.request-link  {
    font-size: 1.8rem;
   
}

.auth-register-container {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.auth-register {
    font-size: 1.8rem;
}

.auth-register-text {
    font-size: 1.8rem;
    color: var(--primary);
}




.reset-sent-notice {
    color: var(--secondary);
    margin: 0;
    padding: 10px 20px;
}

.auth_brand {
    width: 100%;
    max-width: 350px;
}

.ticket-request-title {
    color: var(--primary);
    margin: 0;
}


.setTicketNo {
    border: 1px solid grey;
    padding: 2rem 5rem;
    box-shadow: 1px 1px 2px 2px #888888;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 3rem;
    color: #333;
    font-weight: 600;
}

.password-set-errors {
    color: red;
    font-weight: 600;
    font-size: 1.4rem;
}
@media only screen and (max-width:450px) {
    .forgot-card,
    .enrol-card,
    .login-card {
        margin: 0;
        border-radius: 0;
        padding: 0px;
        
    }
    .auth-wrapper {
        overflow-x: hidden;
        padding: 0;
        padding-top: 25%;
        
    }
    .form-padding {
       margin:0 10px;
       
    }
    .login-btn {
        width: 91%;
    }

    .enrol-cta-container {
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }

    .enrol-cta-container p {
        width: 100%;
        padding: 10px 0;
        text-align: center;
    }

    .enrol-btn {
        width: 100% !important;
    }

    .auth_brand{
        margin-top: 10%;
        width: 80%;
    }
    .auth-ticket-wrapper{
        margin: 0 1rem;
    }
    .ticket-label-title {
        padding: 0 1rem;
    }
}

.ticket-errors {
    color: darkred;
}

.ticket-notice {
    color: var(--primary);
}
.ev-branding {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  float: left;
  width: 100%;
}

.ev-branding img {
  display: block;
  padding: 2px;
  width: 20rem;
  height: 11rem;
  object-fit: contain;
}

.ev-branding h1 {
  
  margin-left: 2rem;
  font-size: 3rem;
  color: var(--secondary) !important;
}

@media only screen and (max-width: 768px) {
  .ev-branding {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    padding-bottom: 2rem;
  }

  .ev-branding h1 {
    text-align: left;
    margin-left: 1rem;
    font-size: 4vw;
  
  }

  .ev-branding img {
    width: 15rem;
    height: 8rem;
  }
}


@media only screen and (max-width: 458px) {
  .ev-branding img {
    width: 7rem;
    height: 4rem;
  }

}

.more-info-container {
display: -webkit-flex;
display: flex;
-webkit-flex-direction: column;
        flex-direction: column;

}

.more-info-text {
    color: var(--primary);
    margin: 0;
}

.more-info-link {
    color: var(--secondary);
    transition: .3s;
    margin-bottom: 1rem;

}

.more-info-link:hover {
    color: var(--primary);
}
.card {
    margin: 5px;
    border-radius: 10px;
    background-color: #EEEEEE;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, .3);
    
}

.card-header {
    border-radius: 10px 10px 0px 0px;
    padding-left: 10px;
    background-color: var(--secondary);
    color: white;
}

.card-header h3 {
    margin: 0;
    padding: 5px;
}


.primary-card {
    background-color: var(--primary);
}

.secondary-card {
    background-color: var(--secondary);
}
.player-wrapper {
    position: relative;
  }
   
  .react-player {
    position: relative;
    min-width: 100%;
    min-height: 100%;
  }

  .react-player video:focus {
      outline: none;
  }


#item {
    margin: 2rem;
    width: 25rem;
    height: 30rem;
    background-color: var(--primary);
    border-radius: .5rem;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    overflow-y: hidden;
}

.thumb-container {
    width: 100%;
    height: 45%;
    overflow:hidden;
    background-color: #c1c1c1;

}
.thumb-container img {
    width: 100%;
    height: 100%;
    object-fit:cover;
}


.item-preview-content {
    padding: .5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;

}

.item-preview-title {
    margin: 0;
    margin: 1rem 0;
    color: white;
    font-weight: 600;
    font-size: 1.8rem;
}

.item-preview-location {
    margin: 0;
    color: var(--secondary);
    font-weight: 200;
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.item-preview-date {
    margin: 0;
    
    font-weight: 600;
    font-size: 1.5rem;
}
#item-list {
    padding: 2rem 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    border-top:.1rem solid #ddd; 

}

.item-list-h2 {
   margin: 0 2rem;
    font-size: 1.6rem;
    color: var(--primary);
    border-bottom: 1px solid var(--secondary);
}

.slider_containter {
    width:100%;
    height: 30rem;
    overflow-x: scroll;
    overflow-y: hidden;
    display: -webkit-flex;
    display: flex;
}

@media only screen and (min-width: 2300px) {
    .slider_containter {
        -webkit-justify-content: space-around;
                justify-content: space-around;
    }
}

@media only screen and (max-width: 768px) {
    .slider_containter {
        width:100%;
        height: 40rem;
        overflow-x: scroll;
        overflow-y: hidden;
        display: -webkit-flex;
        display: flex;
        padding-bottom: 1rem;
    }
}
.duration-container {
    display: -webkit-flex;
    display: flex;
    background-color: var(--secondary);
    color: white;
    margin: .5rem;
    border-radius: 1rem;
    padding: 0 2rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.duration-container p {
    font-size: 1.1rem;
}

.duration-data {
    font-weight: 600;
    margin-left: 1rem;
}
.time-container {
    display: -webkit-flex;
    display: flex;
    background-color: var(--primary);
    color: white;
    margin: .5rem;
    margin-left: 0;
    border-radius: 1rem;
    padding: 0 2rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.time-container p {
    font-size: 1.1rem;
    font-weight: 600;
}

#event-item {
  min-height: 50vh;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  
}

.event-item-video-container {
  position: relative;
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  max-height: 50rem;
  padding: 2rem 0;
}

.item-video {
  margin: 0 auto;
  max-width: 80rem;
}

.goback {
  width: 5rem;
}

.video-info-container {
 display: -webkit-flex;
 display: flex;
 -webkit-justify-content: flex-start;
         justify-content: flex-start;
 -webkit-align-items: center;
         align-items: center;
}
.event-item-content-body {
  margin: 1rem auto;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  max-width: 80rem;
}

.event-item-content-info {
  width:100%;
  padding: 0 2%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}


.item-title {
  margin: 0;
  margin-top: 1rem;
  color: var(--primary);
  font-weight: 600;
  font-size: 3rem;
}

.item-location {
  margin:  0;
  color: var(--secondary);
  font-weight: 200;
  font-size: 2rem;
  padding-bottom: 2rem;
}

.item-date {
  margin: 1rem 0;
  color: var(--primary);
  font-weight: 600;
  font-size: 1.6rem;
  
}

@media only screen and (max-width: 840px) {
  .event-item-content-body {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .event-item-content-body {
    max-width: 100vw;
  }

  .event-item-content-info {
    width: 100vw;
    padding-right: 0%;
    border-bottom: 0.1rem solid #ddd;
    border-right: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
    padding-bottom: 2rem;
  }

  .item-title {
    text-align: center;
    width: 90%;
    font-weight: 600;
    font-size: 2.2rem;
  }

  .item-location {
    text-align: center;
    
  }

  .event-item-content-text {
    width: 90%;
    padding: 2rem 5%;
  }

  .item-date {
    padding-bottom: 0;
  }
}

#feature {
    min-height: 43rem;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    padding: 5rem 2rem;
    background-color: #eee;
    border-bottom: 1px solid #ccc;
}

#video {
    width: 50%;
    max-width: 94rem;
}

.video-container {
    width: 100%;
}

#info {
    width: 40%;
    padding: 2rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}



.info-body {
 -webkit-flex-grow: 2;
         flex-grow: 2;
 padding: 4rem 0;
 border-bottom: 1px solid darkgray;
}

.info-body p {
    color: grey;
    font-size: 1.4rem;
    font-weight: 400;
white-space:pre-wrap;
}

.info-footer {
    margin-top: 1rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.featured-not-found {
    color: var(--primary);
    text-align: center;
    font-size: 2rem;
}



@media (max-width:1200px) {
    #feature {
       -webkit-flex-direction: column;
               flex-direction: column;
    }
    #video, #info {
        
        width:95%;
    }
}

@media (max-width:768px) {
    #feature {
        width: 100%;
        padding: 0;
    }
   
    #video {
        width:100%;
    }
    #info {
        width: 100%;
        padding: 0;
    }
    .feature-title {
        font-size: 6vw;
    }
    .feature-sub-title {
        font-size: 3.5vw;
    }
}

@media (max-width:480px) {

   
    #video {
        width:100%;
    }
    #info {
        width: 100%;
        padding: 0;
    }
}
.feature-header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.feature-title {
    margin: 1rem 0 .3rem 0;
    color: var(--primary);
    font-size: 4rem;
    font-weight: 300;
}

.feature-sub-title {
    margin: .3rem 0 1rem 0;
    color: var(--secondary);
    font-size: 2rem;
    font-weight: 600;
}

.feature-location {
    margin: 0;
    color: var(--primary);
    font-size: 1.8rem;
    font-weight: 900;
}

@media only screen and (max-width: 768px) {
    .feature-header {
       padding: 0 1rem;
    }

    .feature-title {
        font-size: 6vw;
        font-weight: 500;
    }
    
    .feature-sub-title {
        font-size: 4vw;
    }


}

@media only screen and (max-width: 458px) {
    .feature-header {
       padding: 0 1rem;
    }

    .feature-title {
        font-size: 6vw;
        font-weight: 500;
    }
    
    .feature-sub-title {
        font-weight: 900;
    }


}
@media only screen and (max-width: 768px) {
    .page-wrapper {
        overflow-x: hidden;
    }
}
.branding {
    width: 100% ;
    height: 100%;
    display: -webkit-flex;
    display: flex; 
    -webkit-flex-wrap: wrap; 
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    float: left;
  }
  
  .branding img {
    display: block;
    padding: 2px;
    width: 20rem;
    height: 100%;
    object-fit: contain;
   
    
  }

  .branding h1 {
    margin-left: 2rem;
    font-size: 3rem;
    color: var(--primary);
  }

  @media only screen and (max-width:450px) {
    .branding h1 {
      margin-left: 0rem;
      font-size: 1.8rem;
      padding: 0 1rem;
    }
  }
.top-links {
   
    margin:0;
    padding: 0;
   position: absolute;
   width: 5rem;
   height: 5rem;
   top: .5rem;
   right: .5rem;
    display: -webkit-inline-flex;
    display: inline-flex;
    justify-items: center;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 300;
    color: white;
  }

 

  .top-links a, .top-links__logout {
    color: var(--primary);
    text-decoration: none;
    transition: .3s;
  }

  .top-links a {
   
    padding: 0.5rem;
  }


.top-links a:hover,
.top-links a.active, 
.top-links__logout:hover
 {
  
  color: var(--secondary);
  
}

.top-links__logout:active{
  color: #818181;
  color: var(--secondary);
}

.top-links__logout {
    cursor: pointer;
    background: transparent;
    font: inherit;
    border: none;
    color: white;
    font-size: 3rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-end;
            align-items: flex-end;
  }
  
  .top-links__logout:focus {
    outline: none;
    color: var(--secondary);
  }
  
  .top-links__logout:hover,
  .top-links__logout:active {
    color: var(--secondary);
  }
  

  
.main-nav__wrapper {
  color: #ffffff;
  padding-right: 10px;
  font-size: 1.3rem;
  font-weight: 400;
  display: none;
}

.nav-draw__wrapper {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  min-height: 100vh;
  width: 250px;
  padding-top: 90px;
  background: rgb(221, 221, 221);
  background: linear-gradient(
    90deg,
    rgba(221, 221, 221, 1) 31%,
    rgba(201, 201, 201, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dddddd",endColorstr="#c9c9c9",GradientType=1);
  z-index: 999;
  transition: all 0.3s ease;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  overflow-y: scroll;
  box-shadow: -3px 0px 3px rgba(0, 0, 0, 0.2);
}

.mobile-nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  list-style: none;
  margin-top: 2%;
  padding: 0;
  width: 100%;
}
.mobile-nav li {
  margin: 5px 0;
  text-align: left;
  font-size: 2rem;
  border-bottom: 1px dashed #aaa;
}

.mobile-nav-link-button {
  outline: none;
  border: 0;
  background: none;
}

.mobile-nav-link {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 20px;
  font-weight: 400;
  color: #666;
  text-decoration: none;
  font-size: 2rem;
}
.mobile-nav-link span {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--primary);
}
.mobile-nav-link:hover {
  background-color: var(--secondary);
}

.bar-container {
  display: inline-block;
  cursor: pointer;
  position: fixed;
  top: 35px;
  right: 20px;
  color: white;

  padding: 10px;
  border-radius: 10px;
  z-index: 1000;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 2px;
  background-color: #818181;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  width: 30px;
  -webkit-transform: rotate(-45deg) translate(-4px, 3px);
  transform: rotate(-45deg) translate(-4px, 3px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  width: 30px;
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.close {
  width: 0;
}



nav {
  display: -webkit-inline-flex;
  display: inline-flex;
  float: right;
}

p {
  color: white;
  display: inline;
}

.header-container {
  margin: 0;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  top: 0;
  left: 0;
  z-index: 5;
}

.header-container__shell {
  margin: 0 auto;
  padding-bottom: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
}

.header-conatiner__top,
.header-conatiner__nav_top {
  margin: 0;
  padding: 5px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
 
}

.header-conatiner__top{
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
}

.wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.header-conatiner__top {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
 
  background-color: var(--primary);
  z-index: 1000;
}

.header-conatiner__nav_top {
  height: 30px;
  
  z-index: 999;
}

.header-container__info {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 3px 15px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
}

.branding-header-container {

  width: 200px;
  
 
}


.header-brand-text {
  display: none;
}

.header-container__title {
  color: white;
}

.header-container__nav {
  list-style: none;
}

.header-container__link {
  display: inline;
}

.main-navigation__menu-btn {
  margin: 8px 3px;
  width: 2.5rem;
  height: 2rem;
  background: transparent;
  border: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 2rem;
  height: 2px;
  background: white;
}

.main-navigation__header-nav {
  display: -webkit-flex;
  display: flex;
}

.header-container__brand {
  width: 50%;
  display: grid;
  font-family: 'Roboto', sans-serif;
}



@media only screen and (max-width: 450px) {
  .header-container__info {
    width: 100%;
    font-size: 1.5rem;
  }
  .header-email {
    width: 100%;
    text-align: center;
  }
}

footer {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 0;
}

.main-footer__shell {
    margin: 0 auto;
    padding: 0 3rem;
    padding-bottom: 1.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    height: 100%;
   
   
}

.main-footer__brand {
    padding: 2%;

}

.main-footer__links {
    padding: 1.5rem;
    float: left;
}

@media (max-width: 391px) {
    .main-footer__links {
        margin:0;
        width: 100%;
    }
  }

.main-footer__contact {
    
    margin-right: 1rem;
    padding: 1.5rem;
    float: right;
}

.main-footer__bottom {
    margin: 0;
    padding: .5rem;
    padding-top: 1.5rem;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    text-align: right;
    font-size: 1.4rem;

}
.main-footer__bottom p {
  color: grey;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.video {
 width: 50%;
 
}
