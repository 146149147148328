.branding {
    width: 100% ;
    height: 100%;
    display: flex; 
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    float: left;
  }
  
  .branding img {
    display: block;
    padding: 2px;
    width: 20rem;
    height: 100%;
    object-fit: contain;
   
    
  }

  .branding h1 {
    margin-left: 2rem;
    font-size: 3rem;
    color: var(--primary);
  }

  @media only screen and (max-width:450px) {
    .branding h1 {
      margin-left: 0rem;
      font-size: 1.8rem;
      padding: 0 1rem;
    }
  }