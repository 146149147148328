#event-item {
  min-height: 50vh;
  position: relative;
  display: flex;
  flex-direction: column;
  
}

.event-item-video-container {
  position: relative;
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  max-height: 50rem;
  padding: 2rem 0;
}

.item-video {
  margin: 0 auto;
  max-width: 80rem;
}

.goback {
  width: 5rem;
}

.video-info-container {
 display: flex;
 justify-content: flex-start;
 align-items: center;
}
.event-item-content-body {
  margin: 1rem auto;
  display: flex;
  width: 100%;
  max-width: 80rem;
}

.event-item-content-info {
  width:100%;
  padding: 0 2%;
  display: flex;
  flex-direction: column;
}


.item-title {
  margin: 0;
  margin-top: 1rem;
  color: var(--primary);
  font-weight: 600;
  font-size: 3rem;
}

.item-location {
  margin:  0;
  color: var(--secondary);
  font-weight: 200;
  font-size: 2rem;
  padding-bottom: 2rem;
}

.item-date {
  margin: 1rem 0;
  color: var(--primary);
  font-weight: 600;
  font-size: 1.6rem;
  
}

@media only screen and (max-width: 840px) {
  .event-item-content-body {
    flex-direction: column;
  }

  .event-item-content-body {
    max-width: 100vw;
  }

  .event-item-content-info {
    width: 100vw;
    padding-right: 0%;
    border-bottom: 0.1rem solid #ddd;
    border-right: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 2rem;
  }

  .item-title {
    text-align: center;
    width: 90%;
    font-weight: 600;
    font-size: 2.2rem;
  }

  .item-location {
    text-align: center;
    
  }

  .event-item-content-text {
    width: 90%;
    padding: 2rem 5%;
  }

  .item-date {
    padding-bottom: 0;
  }
}
