.player-wrapper {
    position: relative;
  }
   
  .react-player {
    position: relative;
    min-width: 100%;
    min-height: 100%;
  }

  .react-player video:focus {
      outline: none;
  }

