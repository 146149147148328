
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap');


html {
  margin: 0;
  position: relative;
  min-height: 100vh;
  font-size: 62.5%;
  
}

body {
  margin: 0; 
  min-height: 650px;
  font-family: 'Red Hat Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  color: #c3c3c3;
}

:root {
  --primary-100: #6650AA;
  --primary: #4D397D;
  --primary-500: #32245E;

  --secondary-100: #EEEEEE;
  --secondary: #EA8900;
  --secondary-500: #bf7002;

  --input-color: #b0b0b0;
  --input-border: #d2d2d2;
  --input-background: #fff;
  --input-placeholder: #CBD1DC;

  --input-border-focus: #6650AA;

  --group-color: var(--input-color);
  --group-border: var(--input-border);
  --group-background: #ffeeee;

  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: var(--primary);

}

a {
  color: var(--primary);
}

a:hover {
  color: var(--primary-500);
}

.helogale {
  color: white;
  text-decoration: none;
  opacity: .6;
}

.center {
  margin: 0 auto;
  text-align:  center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.left {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  
}

.page-wrapper {
  margin: 0 auto;
  padding-top: 8rem;
  margin-bottom: 5.6rem;
  width: 100%;
  min-height: 500px;
}


.header-container {
  position: fixed;
  z-index: 500 !important;
}